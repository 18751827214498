import React from 'react';
import styled from 'styled-components';

const domain = process.env.GATSBY_CHAT_DOMAIN || 'www.horny.moe';

const ChatContainer = styled.div`
  min-height: 504px;
`;

const StreamChat = () => {
  const iframe = `<iframe id="chat_embed" src="https://www.twitch.tv/embed/hornydotmoe/chat?parent=${domain}" frameborder="0" scrolling="no" height="100%" width="100%" min-height:300px;></iframe>`;
  return (
    <ChatContainer className="col-12 col-lg-4">
      <div className="h-100 mt-3 mt-lg-0" dangerouslySetInnerHTML={{ __html: iframe }} />
    </ChatContainer>
  );
};

export default StreamChat;
