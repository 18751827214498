import React from 'react';
import axios from 'axios';
import { Link } from 'gatsby';
import ReactHlsPlayer from 'react-hls-player';
import offline from '../../images/offline.jpg';

class StreamPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMounted: false,
      src: '',
      poster: offline,
      controls: false,
      isLive: false,
    };
  }

  componentDidMount() {
    this.setState({ isMounted: true });
    this.getStreamStatus();
    this.checker = setInterval(this.getStreamStatus, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.checker);
  }

  getStreamStatus = async () => {
    const { isLive } = this.state;
    const request = await axios.get('https://live.horny.moe/status');
    if (request.status === 200 && !isLive) {
      await this.setState({
        isLive: true,
        controls: true,
        poster: '',
        src: 'https://live.horny.moe/hls/test.m3u8',
      });
    } else if (request.status === 204 && isLive) {
      await this.setState({
        isLive: false,
        controls: false,
        poster: offline,
        src: '',
      });
    }
  }

  render() {
    const {
      isMounted, src, poster, controls,
    } = this.state;
    if (isMounted) {
      return (
        <div className="col-12 col-lg-8">
          <ReactHlsPlayer
            src={src}
            controls={controls}
            poster={poster}
            autoPlay
            muted
            width="100%"
            height="auto"
            hlsConfig={{
              maxLoadingDelay: 4,
              minAutoBitrate: 0,
            }}
          />
          <h5 className="mt-4 text-right">
            <Link to="/stream-subscribe/">
              Email me when a stream goes live
              {'  '}
              <i className="d-inline fas fa-bell" />
            </Link>
          </h5>
        </div>
      );
    }
    return (<></>);
  }
}

export default StreamPlayer;
